import {CartType, ProductType, ShippingRuleStatus} from '@wix/wixstores-client-core/dist/es/src/types';
import {CartApi} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/CartApi/CartApi';
import {
  CommandDataApplyCoupon,
  CommandDataSetAddress,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/types/executor.types';
import {
  IPlaceOrderParams,
  PlaceOrderResponse,
  SelectedShippingOption,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/cart/cartApi/CartApi';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {FedopsInteractions, ORIGIN as origin} from '../../components/cart/constants';
import {ICart, ICartControllerApi, ICartItem} from '../../types/app.types';
import {BIService} from './BIService';
import {StyleSettingsService} from './StyleSettingsService';
import {TrackEventName} from '@wix/wixstores-client-core/dist/es/src/types/track-event';
import _ from 'lodash';

export type CouponError = {
  code: string;
  message: string;
};

export class CartService {
  private readonly controllerApi: ICartControllerApi;
  private readonly siteStore: SiteStore;
  private readonly biService: BIService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly cartApi: CartApi;
  public couponError: CouponError = null;
  public cart: ICart;

  constructor({
    controllerApi,
    siteStore,
    biService,
    styleSettingsService,
  }: {
    controllerApi: ICartControllerApi;
    siteStore: SiteStore;
    biService: BIService;
    styleSettingsService: StyleSettingsService;
  }) {
    this.controllerApi = controllerApi;
    this.siteStore = siteStore;
    this.biService = biService;
    this.styleSettingsService = styleSettingsService;
    this.cartApi = new CartApi({siteStore, origin});
  }

  public async fetchCart(): Promise<void> {
    const {shouldShowTax, shouldShowShipping} = this.styleSettingsService;

    this.cart = await this.cartApi.fetchCart({
      locale: this.siteStore.locale,
      withShipping: shouldShowShipping,
      withTax: shouldShowTax,
    });
  }

  public async fetchCartWithTaxAndShippingIncluded(): Promise<ICart> {
    return this.cartApi.fetchCart({
      locale: this.siteStore.locale,
      withShipping: true,
      withTax: true,
    });
  }

  public get cartType(): CartType {
    const hasDigital = this.cart?.items.some((item) => item.product.productType === ProductType.DIGITAL);
    const hasPhysical = this.cart?.items.some(
      (item) => !item.product.productType || item.product.productType === ProductType.PHYSICAL
    );

    if (hasDigital && hasPhysical) {
      return CartType.MIXED;
    }
    /* istanbul ignore else */
    if (hasDigital) {
      return CartType.DIGITAL;
    }
    /* istanbul ignore next */
    return CartType.PHYSICAL;
  }

  public get isDigitalCart(): boolean {
    return this.cartType === CartType.DIGITAL;
  }

  public get isZeroCart(): boolean {
    return this.cart.totals.total === 0;
  }

  public get isEmpty(): boolean {
    return !this.cart?.items.length;
  }

  public get areAllItemsInStock(): boolean {
    return (
      this.cart?.items &&
      this.cart.items.every((item) => _.isNull(item.inventoryQuantity) || item.inventoryQuantity > 0)
    );
  }

  public get isFullAddressRequired() {
    return this.cart.shippingRuleInfo?.status === ShippingRuleStatus.FullAddressRequired;
  }

  public get itemsCount(): number {
    return this.cart.items.reduce((count, item) => count + item.quantity, 0);
  }

  public getCheckoutId(): Promise<string | undefined> {
    return this.cartApi.getCheckoutId({cartId: this.cart.cartId});
  }

  public readonly updateItemQuantity = async ({cartItemId, product}: ICartItem, quantity: number): Promise<void> => {
    return this.cartApi.updateItemQuantity({cartItemId, quantity}, {cart: this.cart, productId: product.id});
  };

  public readonly updateBuyerNote = async (content: string) => {
    await this.cartApi.updateBuyerNote({content}, {cart: this.cart});
  };

  public readonly removeItemFromCart = async (item: ICartItem): Promise<void> => {
    return this.cartApi.removeItem({cartItemId: item.cartItemId}, {cart: this.cart, item});
  };

  public readonly trackInitiateCheckout = () => {
    const productsInfo = this.cart.items.map((item) => ({
      id: item.product.id,
      name: item.product.name,
      category: 'All Products',
      price: item.product.price,
      currency: this.siteStore.currency,
      quantity: item.quantity,
    }));

    this.siteStore.trackEvent(TrackEventName.INITIATE_CHECKOUT, {
      contents: productsInfo,
      origin: 'Stores',
    });
  };

  public readonly setShippingAddressesForFastFlow = ({
    cartId,
    country,
    subdivision,
    zipCode,
  }: {
    cartId: string;
    country: string;
    subdivision?: string;
    zipCode?: string;
  }): Promise<void> => {
    return this.cartApi.setCartShippingAddressesForFastFlowEstimation({
      cartId,
      address: {country, subdivision, zipCode},
    });
  };

  public readonly setCartAddress = ({cartId, address}: CommandDataSetAddress): Promise<void> => {
    return this.cartApi.setCartAddress(cartId, address);
  };

  public readonly setCartBillingAddress = (data: Parameters<CartApi['setCartBillingAddress']>[0]): Promise<void> => {
    return this.cartApi.setCartBillingAddress(data);
  };

  public readonly placeOrder = (params: IPlaceOrderParams): Promise<PlaceOrderResponse> => {
    return this.cartApi.placeOrder(params);
  };

  public readonly setDestinationForEstimation = ({
    country,
    subdivision,
    zipCode,
  }: {
    country: string;
    subdivision?: string;
    zipCode?: string;
  }): Promise<void> => {
    return this.cartApi.setDestinationForEstimation({destination: {country, subdivision, zipCode}});
  };

  public readonly setShippingOption = (
    cartId: string,
    selectedShippingOption: SelectedShippingOption
  ): Promise<void> => {
    return this.cartApi.setShippingOption({cartId, selectedShippingOption});
  };

  private get isMemberLoggedIn(): boolean {
    return !!this.siteStore.usersApi.currentUser && !!this.siteStore.usersApi.currentUser.id;
  }

  public readonly clearCouponError = (): void => {
    this.couponError = null;
  };

  public readonly applyCoupon = async (couponCode: string): Promise<void> => {
    const couponData: CommandDataApplyCoupon = {
      code: couponCode,
    };

    if (this.siteStore.usersApi.currentUser.loggedIn) {
      couponData.userIdentifier = await this.siteStore.usersApi.currentUser.getEmail();
    }

    await this.cartApi.applyCoupon(couponData, {isMember: this.isMemberLoggedIn, cart: this.cart}).catch((e) => {
      if (e.success === false) {
        const errorCode = e.errors[0].code;
        this.biService.errorWhenApplyingACouponSf(this.cart, couponCode, errorCode);
        this.controllerApi.reportFedopsInteractionEnded(FedopsInteractions.ApplyCouponInteraction);
        this.couponError = {
          code: errorCode,
          message: e.errors[0].message,
        };
      }
      throw e;
    });
  };

  public readonly removeCoupon = (id: string): Promise<void> => {
    return this.cartApi.removeCoupon({id}, {isMember: this.isMemberLoggedIn, cart: this.cart});
  };
}
